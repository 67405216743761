import React from 'react'

import { Typography, Grid, Box, List, ListItem, Divider} from '@mui/material';
import { RadioButtonUnchecked } from '@mui/icons-material';

import Seo from "components/Seo";
import Breadcrumbs from "components/Breadcrumbs";

import { Product } from "types";
import {styled} from "@mui/material/styles";

import { replaceSpace } from "utilities";
import ImageCarousel from "../components/ImageCarousel";

const BoldTypography = styled(Typography)(({theme}) => ({
  fontWeight: 'bold',
  display: 'inline-block',
  marginRight: theme.spacing(),
}));

const BoxFlex = styled(Box)(() => ({
  display: 'flex',
  flex: 'auto',
  flexWrap: 'wrap',
}));

const StockTypography = styled(Typography)(({theme}) => ({
  display: 'inline-block',
  color: theme.palette.common.white,
  padding: `2px ${theme.spacing(1)}`,
  fontSize: 12,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
}));

/**
 * Productmmm
 */
const ProductTemplate: React.FC<{ pageContext: Product }> = (props) => {
  const { pageContext, children } = props;
  const {
    name,
    images,
    category,
    id,
    size,
    stock,
    exterior_material,
    interior_material,
    logo,
    weight,
    description,
  } = pageContext || {};
  const toString = '/products/'+ replaceSpace(category)
  const seoImage = (images || []).find(item => item.url);
  return (
    <>
      <Seo title={name} description={description} url={seoImage?.url}/>

      <Breadcrumbs
        dataBreadcrumb={
          [
            {
              id,
              name,
            },
            {
              id: category,
              name: category,
              to: toString,
            },
            {
              id: 'produse',
              name: "Produse",
              to: '/products',
            },
          ]}
      />
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={5}>
          <ImageCarousel images={images} name={name} />
        </Grid>
        <Grid item xs={12} md={7}>
          <Box  sx={{mb: 4}}>
            <Typography variant="h5" component="h1" sx={{mb: 1, position: 'relative'}} color="primary">
              {name}
            </Typography>
            <Divider variant="fullWidth" sx={{maxWidth: 300}}/>
            {stock
              ? <StockTypography sx={{ bgcolor: 'success.main' }}>În stoc</StockTypography>
              : <StockTypography sx={{ bgcolor: 'error.main' }}>Stoc epuizat</StockTypography>
            }
          </Box>

          <BoldTypography>Descriere: </BoldTypography>
          <Typography color="textSecondary" gutterBottom>{description}</Typography>
          <br />
          <BoldTypography>Specificatii: </BoldTypography>
          <List dense>
            <ListItem>
              <RadioButtonUnchecked sx={{width: 8, height: 8, mr: 2}}/>
              <BoxFlex>
                <BoldTypography color="textSecondary">Dimensiuni:</BoldTypography>
                <Typography color="textSecondary">{size}</Typography>
              </BoxFlex>
            </ListItem>

            <ListItem>
              <RadioButtonUnchecked sx={{width: 8, height: 8, mr: 2}}/>
              <BoxFlex>
                <BoldTypography color="textSecondary">Material interior:</BoldTypography>
                <Typography color="textSecondary">{interior_material}</Typography>
              </BoxFlex>
            </ListItem>
            <ListItem>
              <RadioButtonUnchecked sx={{width: 8, height: 8, mr: 2}}/>
              <BoxFlex>
                <BoldTypography color="textSecondary">Material exterior:</BoldTypography>
                <Typography color="textSecondary">{exterior_material}</Typography>
              </BoxFlex>
            </ListItem>
            <ListItem>
              <RadioButtonUnchecked sx={{width: 8, height: 8, mr: 2}}/>
              <BoxFlex>
                <BoldTypography color="textSecondary">Siglă:</BoldTypography>
                <Typography color="textSecondary">{logo}</Typography>
              </BoxFlex>
            </ListItem>
            <ListItem>
              <RadioButtonUnchecked sx={{width: 8, height: 8, mr: 2}}/>
              <BoxFlex>
                <BoldTypography color="textSecondary">Greutate:</BoldTypography>
                <Typography color="textSecondary">{weight}</Typography>
              </BoxFlex>
            </ListItem>
          </List>

        </Grid>
      </Grid>


      {children}
    </>
  )
}


export default ProductTemplate;
