import React, { useEffect, useRef, useState } from "react";
import {styled} from "@mui/material/styles";

import { ImageType } from 'types'
import { CardMedia, Button } from "@mui/material";

import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const ImageCarouselWrap = styled('div')`
  margin: ${props => `${props.theme.spacing(2)} 0 ${props.theme.spacing(4)}`};
  .carousel {
    min-height: 60px;
    position: relative;
    padding: ${props => `${props.theme.spacing(1)} ${props.theme.spacing(8)}`};
    ${props => props.theme.breakpoints.down("sm")} {
      padding: ${props => `${props.theme.spacing(1)} ${props.theme.spacing(8)}`};
    }
  }

  .carousel__images {
    display: flex;
    max-width: 100%;
    overflow-x: hidden;
    ${props => props.theme.breakpoints.down("sm")} {
      display: none
    }
  }

  .carousel__image-selected {
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.theme.palette.primary.main};
  }

  .carousel__image {
    margin-right: 5px;
    margin-left: 5px;
    height: 120px;
    min-width: 150px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    ${props => props.theme.breakpoints.down("sm")} {
      min-width: 190px;
    }
  }

  .carousel__button {
    position: absolute;
    top: 30%;
    min-width: 10px;
    padding: ${props => props.theme.spacing()};
    border-radius: 200px;
    ${props => props.theme.breakpoints.down("sm")} {
      top: 0;
    }
  }

  .carousel__button-left {
    left: 0;
  }

  .carousel__button-right {
    right: 0;
  }
`


const ImageCarousel: React.FC<{ images?: ImageType[], name: string }> = ({ images, name }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState<ImageType>();
  const carouselItemsRef = useRef<HTMLDivElement[] | null[]>([]);

  useEffect(() => {
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(
        0,
        images.length
      );

      setSelectedImageIndex(0);
      setSelectedImage(images[0]);
    }
  }, [images]);

  const handleSelectedImageChange = (newIdx: number) => {
    if (images && images.length > 0) {
      setSelectedImage(images[newIdx]);
      setSelectedImageIndex(newIdx);
      console.log(carouselItemsRef?.current[newIdx])
      if (carouselItemsRef?.current[newIdx]) {
        carouselItemsRef?.current[newIdx]?.scrollIntoView({
          inline: "center",
          block : "end",
          behavior: "smooth"
        });
      }
    }
  };

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= images.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = images.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  return (
    <ImageCarouselWrap className="carousel-container">
      <CardMedia
        component="img" image={selectedImage?.url} alt={name} />
      <div className="carousel">
        <div className="carousel__images">
          {images &&
            images.map((image, idx) => (
              <div
                onClick={() => handleSelectedImageChange(idx)}
                style={{ backgroundImage: `url(${image.url})` }}
                key={image.id}
                className={`carousel__image ${
                  selectedImageIndex === idx && "carousel__image-selected"
                }`}
                ref={(el) => (carouselItemsRef.current[idx] = el)}
              />
            ))}
        </div>
        <Button
          className="carousel__button carousel__button-left"
          onClick={handleLeftClick}
          variant="outlined"
        >
          <ChevronLeft fontSize="large" />
        </Button>
        <Button
          variant="outlined"
          className="carousel__button carousel__button-right"
          onClick={handleRightClick}
        >
          <ChevronRight fontSize="large" />
        </Button>
      </div>
    </ImageCarouselWrap>
  );
};

export default ImageCarousel;
